import { ApolloError } from '@apollo/client';
import { CloseOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { TimeValidationError } from '@mui/x-date-pickers';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers';
import {
  addDays,
  differenceInMinutes,
  isAfter,
  parse,
  parseISO,
} from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useState } from 'react';

import { TimesheetItem } from '../..';
import { TimesheetStatusChip } from '../../../WorkerTable/workerUtils';
import TimesheetInformation from '../../TimesheetInformation';
import NextDateAlert from '../PendingTimesheetModal/NextDateAlert';

import MapView from './mapView';

import { AccountDollarIcon } from '@/assets/icons';
import { SubmitErrorAlert, SubmitErrorAlertProps } from '@/components/Alerts';
import Button from '@/components/Button';
import Modal, {
  HeaderDialog,
  StyledAccordion,
  TimeIns,
  TimeNet,
  TitleDialog,
} from '@/components/Modal';
import ShiftTimePicker from '@/components/ShiftTimePicker';
import Stack from '@/components/Stack';
import { ProfileHeading, Small } from '@/components/Typography';
import { GAEvent } from '@/constants/gaevents';
import { UNKNOWN_ERROR_TEXT } from '@/constants/text';
import Form from '@/form';
import TextField from '@/form/TextField';
import {
  GetJobDocument,
  useApproveTimesheetMutation,
  useCreateTimesheetMutation,
  useRejectTimesheetMutation,
  useSaveTimesheetMutation,
  useUnapproveTimesheetMutation,
  useUnrejectTimesheetMutation,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import { getTotalLengthOfShiftTimesheet } from '@/routes/Agency/Job/util';
import PermissionComponent from '@/routes/PermissionComponent';
import { Maybe } from '@/types';
import { Address, Scalars, Shift, TimesheetStatusEnum } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import {
  formatISO,
  getFormattedDateInTimeZone,
  isDateInvalid,
} from '@/util/date';
import { handleGraphQLError } from '@/util/error';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';

export type Props = {
  jobAddress: Address;
  timesheet?: TimesheetItem;
  jobId: string;
  hideModal: () => Scalars['Void'];
  shift: Pick<Shift, 'id' | 'endAt' | 'startAt'>;
  variant?: 'DETAIL' | 'DEFAULT';
  isCancelJobTimesheet: boolean;
  workerInfo: any;
};

type FormValues = {
  approvedBreakMinutes: string;
  approvedCheckinAt: string;
  approvedCheckoutAt: string;
  ratingComment: TimesheetItem['reportComment'];
  tipAmount: TimesheetItem['tipAmount'];
  save: boolean;
};

type TimesheetFormattedFormValues = {
  timesheetId: string;
  breakMinutes: number;
  checkinAt: string;
  checkoutAt: string;
  tipAmount: number;
  ratingComment: TimesheetItem['reportComment'];
};

const EditTimesheetModal = ({
  jobAddress,
  timesheet,
  jobId,
  shift,
  workerInfo,
  hideModal,
  variant = 'DEFAULT',
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 790px)');
  return (
    <>
      {phoneOnly ? (
        <Dialog
          PaperProps={{
            style: {
              maxHeight: '90%',
              borderRadius: '15px',
            },
          }}
          aria-labelledby="second-options-dialog"
          fullScreen={true}
          open={true}
          sx={{
            '& .MuiDialog-container': {
              alignItems: 'end',
              marginBottom: '2px',
            },
          }}
          onClose={hideModal}
        >
          <HeaderDialog>
            <Stack>
              <TitleDialog>
                {`${workerInfo.user.firstName.trim()}'s Timesheet`}
              </TitleDialog>
              <TimesheetStatusChip status={timesheet?.status} />
            </Stack>
            <IconButton aria-label="close" onClick={hideModal}>
              <CloseOutlined />
            </IconButton>
          </HeaderDialog>
          <DialogContent
            style={{
              padding: '25px 24px 20px',
              height: variant === 'DETAIL' ? 'auto' : '76vh',
              overflow: 'auto',
            }}
          >
            <TimesheetModalContent
              hideModal={hideModal}
              jobAddress={jobAddress}
              jobId={jobId}
              phoneOnly={phoneOnly}
              shift={shift}
              timesheet={timesheet}
              variant={variant}
              workerInfo={workerInfo}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <Modal
          size="xs"
          title={`${workerInfo.user.firstName.trim()}'s Timesheet`}
          wrapperBackground={true}
          onRequestClose={hideModal}
        >
          <Stack gap={15} style={{ padding: 20 }}>
            <TimesheetModalContent
              hideModal={hideModal}
              jobAddress={jobAddress}
              jobId={jobId}
              phoneOnly={phoneOnly}
              shift={shift}
              timesheet={timesheet}
              variant={variant}
              workerInfo={workerInfo}
            />
          </Stack>
        </Modal>
      )}
    </>
  );
};

interface TimesheetModalContentProps {
  jobAddress: Address;
  timesheet?: TimesheetItem;
  jobId: string;
  hideModal: () => Scalars['Void'];
  shift: Pick<Shift, 'id' | 'endAt' | 'startAt'>;
  variant?: 'DETAIL' | 'DEFAULT';
  workerInfo: any;
  phoneOnly: boolean;
}

const TimesheetModalContent = ({
  timesheet,
  variant,
  jobAddress,
  workerInfo,
  shift,
  jobId,
  hideModal,
  phoneOnly,
}: TimesheetModalContentProps) => {
  const { coords: jobCoords } = jobAddress;
  const { currentAdminIsCustomerAdmin } = useAuth();
  const { logEvent } = useAnalytics();
  const tipsInCentsWeb = useFeatureValue(FEATURE_TOGGLE.TipsInCentsWeb, false);
  const initialValues: FormValues = useMemo((): FormValues => {
    return {
      approvedBreakMinutes: timesheet?.reportedBreakMinutes ?? '',
      approvedCheckinAt: timesheet?.reportedCheckinAt
        ? formatISO(timesheet.reportedCheckinAt, 'HH:mm', jobAddress.timezone)
        : '',
      approvedCheckoutAt: timesheet?.reportedCheckoutAt
        ? formatISO(timesheet.reportedCheckoutAt, 'HH:mm', jobAddress.timezone)
        : '',
      ratingComment: timesheet?.reportComment || '',
      tipAmount: timesheet?.tipAmount ?? 0,
      save: true,
    };
  }, [timesheet]);

  const workerId = workerInfo.id;

  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [submitError, setSubmitError] =
    useState<Maybe<SubmitErrorAlertProps>>(null);

  const handleOnErrorTimesheetMutation = (error: ApolloError) => {
    handleGraphQLError(error, {
      INVALID_DATES: () => {
        setSubmitError({
          title: 'Invalid shift times',
          description: 'description TEST',
        });
      },
      WORKING_TIME_TOO_SHORT: ({ message }) => {
        setSubmitError({
          title: 'Approved time must be longer',
          description: message,
        });
      },
      BREAK_TIME_TOO_LONG: ({ message }) => {
        setSubmitError({
          title: 'Break time must be short',
          description: message,
        });
      },
      SHIFT_TOO_LONG: () => {
        setSubmitError({
          title: 'Shift length too long',
          description: 'Shifts cannot exceed 24 hours.',
        });
      },
      all: () => {
        setSubmitError({
          description: UNKNOWN_ERROR_TEXT,
        });
      },
    });
  };

  const [saveTimesheet, { loading: isSaveTimesheetLoading }] =
    useSaveTimesheetMutation({
      refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
      onCompleted: () => {
        logEvent(GAEvent.TimesheetSave, timesheet?.id);
        hideModal();
      },
      onError: handleOnErrorTimesheetMutation,
    });
  const [createTimesheet] = useCreateTimesheetMutation({
    onError: handleOnErrorTimesheetMutation,
  });

  const [approveTimesheet, { loading: isApproveTimesheetLoading }] =
    useApproveTimesheetMutation({
      refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
      onCompleted: () => {
        logEvent(GAEvent.TimesheetApprove, timesheet?.id);
        hideModal();
      },
      onError: handleOnErrorTimesheetMutation,
    });

  const [rejectTimesheet, { loading: isRejectTimesheetLoading }] =
    useRejectTimesheetMutation({
      refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
      onCompleted: () => {
        logEvent(GAEvent.TimesheetReject, timesheet?.id);
        hideModal();
      },
      onError: (error) => {
        handleGraphQLError(error, {
          TIMESHEET_APPROVED: () => {
            setSubmitError({
              title: 'Timesheet already approved',
              description: 'The timesheet is already approved',
            });
          },
          all: () => {
            setSubmitError({
              description: UNKNOWN_ERROR_TEXT,
            });
          },
        });
      },
    });

  const [unapproveTimesheet, { loading: isUnapproveTimesheetLoading }] =
    useUnapproveTimesheetMutation({
      fetchPolicy: 'no-cache',
      refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
      onError: (error) => {
        setSubmitError({
          description: error.message,
        });
      },
      onCompleted: () => {
        logEvent(GAEvent.TimesheetUnapprove, timesheet?.id);
        hideModal();
      },
    });

  const [unRejectTimesheet] = useUnrejectTimesheetMutation({
    refetchQueries: [{ query: GetJobDocument, variables: { jobId } }],
    onError: (error) => {
      setSubmitError({
        description: error.message,
      });
    },
    onCompleted: () => {
      logEvent(GAEvent.TimesheetUnReject, timesheet?.id);
      hideModal();
    },
  });

  const handleUnapprove = (timesheetId) => {
    unapproveTimesheet({ variables: { timesheetId } });
  };
  const handleUnReject = (timesheetId) => {
    unRejectTimesheet({
      variables: {
        timesheetId,
      },
    });
  };

  const handleFormValuesChange = (fieldContext, fieldId: keyof FormValues) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };
  const handleSubmit = useCallback(async () => {
    setSubmitError(null);
    const {
      save,
      approvedCheckinAt,
      approvedCheckoutAt,
      approvedBreakMinutes,
      tipAmount,
    } = formValues;

    let { startAt, endAt } = getParsedDateRange(
      approvedCheckinAt,
      approvedCheckoutAt,
      timesheet?.shift.startAt || shift.startAt,
    );

    if (isDateInvalid(startAt) || isDateInvalid(endAt)) {
      const timeLabel = isDateInvalid(startAt) ? 'start time' : 'end time';

      setSubmitError({
        title: 'Shift times are invalid',
        description: `Enter ${timeLabel} to approve.`,
      });
      return;
    }

    let timesheetId = timesheet?.id;
    if (!timesheetId) {
      const createResp = await createTimesheet({
        variables: {
          jobId,
          workerId,
        },
      });

      if (createResp?.data?.timesheetCreate.timesheet.id) {
        timesheetId = createResp?.data?.timesheetCreate.timesheet.id;
      } else {
        return;
      }
    }

    if (isAfter(startAt, endAt)) {
      endAt = addDays(endAt, 1);
    }

    const timesheetFormattedFormValues: TimesheetFormattedFormValues = {
      timesheetId,
      breakMinutes: Number(approvedBreakMinutes),
      checkinAt: getFormattedDateInTimeZone(
        startAt,
        jobAddress.timezone,
      ).toISOString(),
      checkoutAt: getFormattedDateInTimeZone(
        endAt,
        jobAddress.timezone,
      ).toISOString(),
      tipAmount: Number(tipAmount),
      ratingComment: '',
    };

    if (save) {
      await handleSaveTimesheet(timesheetFormattedFormValues);
    } else {
      handleSaveTimesheet(timesheetFormattedFormValues);
      await handleApproveTimesheet(timesheetFormattedFormValues);
    }
  }, [formValues, timesheet?.id]);

  const getParsedDateRange = (
    approvedCheckinAt: Scalars['String'],
    approvedCheckoutAt: Scalars['String'],
    shiftStartAt: string,
  ) => {
    const startAt = parse(approvedCheckinAt, 'HH:mm', parseISO(shiftStartAt));
    const endAt = parse(approvedCheckoutAt, 'HH:mm', parseISO(shiftStartAt));

    return { startAt, endAt };
  };

  const handleApproveTimesheet = async (
    timesheetFormattedFormValues: TimesheetFormattedFormValues,
  ) => {
    const { checkinAt, checkoutAt, breakMinutes, ...rest } =
      timesheetFormattedFormValues;

    await approveTimesheet({
      variables: {
        approvedCheckinAt: checkinAt,
        approvedCheckoutAt: checkoutAt,
        approvedBreakMinutes: breakMinutes,
        ...rest,
      },
    });
  };

  const handleSaveTimesheet = async (
    timesheetFormattedFormValues: TimesheetFormattedFormValues,
  ) => {
    // Only tenant admins can save timesheets
    const { checkinAt, checkoutAt, breakMinutes, ...rest } =
      timesheetFormattedFormValues;

    await saveTimesheet({
      variables: {
        reportedCheckinAt: checkinAt,
        reportedCheckoutAt: checkoutAt,
        reportedBreakMinutes: breakMinutes,
        ...rest,
      },
    });
  };

  const handleRejectTimesheet = useCallback(async () => {
    let timesheetId = timesheet?.id;
    if (!timesheetId) {
      const createResp = await createTimesheet({
        variables: {
          jobId,
          workerId,
        },
      });
      if (createResp?.data?.timesheetCreate.timesheet.id) {
        timesheetId = createResp?.data?.timesheetCreate.timesheet.id;
      } else {
        return;
      }
    }
    rejectTimesheet({
      variables: {
        timesheetId,
        ratingComment: formValues.ratingComment,
      },
    });
  }, [formValues, timesheet?.id]);

  const approvedNetHours = () => {
    if (formValues.approvedCheckinAt && formValues.approvedCheckoutAt) {
      let { startAt, endAt } = getParsedDateRange(
        formValues.approvedCheckinAt,
        formValues.approvedCheckoutAt,
        timesheet?.shift.startAt || shift.startAt,
      );

      if (isAfter(startAt, endAt)) {
        endAt = addDays(endAt, 1);
      }
      return (
        differenceInMinutes(endAt, startAt) -
        Number(formValues.approvedBreakMinutes)
      );
    }
    return 0;
  };

  const handleTimeChange = (
    time: Dayjs,
    event: PickerChangeHandlerContext<TimeValidationError>,
    key: string,
  ) => {
    const value = time ? time?.format('HH:mm') : '';
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const MapAccordian = () => {
    return (
      <StyledAccordion
        style={{
          width: '100%',
          boxShadow: 'none',
          marginTop: '10px',
        }}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          expandIcon={<ExpandMoreIcon />}
          id="panel1-header"
        >
          <ProfileHeading>View Check In/Check Out Location</ProfileHeading>
        </AccordionSummary>
        <AccordionDetails>
          <MapView jobAddress={jobAddress} timesheet={timesheet} />
        </AccordionDetails>
      </StyledAccordion>
    );
  };

  return (
    <Stack vertical gap={0}>
      <Stack vertical align="start" gap={0}>
        <TimesheetInformation
          jobCoords={jobCoords}
          timesheet={timesheet}
          jobAddress={jobAddress}
          variant={variant}
        />
        {submitError && (
          <SubmitErrorAlert
            marginVertical={true}
            status="danger"
            {...submitError}
          />
        )}
        {variant === 'DEFAULT' && (
          <Stack
            vertical
            gap={0}
            style={{
              marginTop: '12px',
            }}
          >
            <Stack vertical verticalGap={0}>
              <ProfileHeading>Confirm Hours</ProfileHeading>
              <TimeIns>Enter Check In, Check Out, and Break times.</TimeIns>
            </Stack>
            <Form
              data-testid="timesheet-form"
              id="timesheet-form"
              initialValues={formValues}
              style={{ width: '100%' }}
              onSubmit={handleSubmit}
            >
              <Stack vertical verticalGap={'0'}>
                <Stack horizontalGap={'14px'}>
                  <Stack vertical gap={5}>
                    <Small color={'light'} weight={'medium'}>
                      Check In
                    </Small>
                    <ShiftTimePicker
                      defaultValue={dayjs(
                        timesheet?.reportedCheckinAt
                          ? formatISO(
                              timesheet.reportedCheckinAt,
                              'HH:mm',
                              jobAddress.timezone,
                            )
                          : '',
                        'HH:mm',
                      )}
                      label="Check In"
                      name="approvedCheckinAt"
                      timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                      onChange={handleTimeChange}
                    />
                  </Stack>
                  <Stack vertical gap={5}>
                    <Small color={'light'} weight={'medium'}>
                      Check Out
                    </Small>
                    <ShiftTimePicker
                      defaultValue={dayjs(
                        timesheet?.reportedCheckoutAt
                          ? formatISO(
                              timesheet.reportedCheckoutAt,
                              'HH:mm',
                              jobAddress.timezone,
                            )
                          : '',
                        'HH:mm',
                      )}
                      label="Check Out"
                      name="approvedCheckoutAt"
                      timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                      onChange={handleTimeChange}
                    />
                  </Stack>
                  {timesheet && (
                    <NextDateAlert
                      checkinAt={timesheet.reportedCheckinAt}
                      checkoutAt={timesheet.reportedCheckoutAt}
                    />
                  )}
                </Stack>
                <Stack
                  wrap
                  align={'center'}
                  horizontalGap={'17px'}
                  style={{
                    marginBottom: '6px',
                    marginTop: '5px',
                  }}
                >
                  <Stack
                    style={{
                      flex: '0 0 48%',
                    }}
                  >
                    <TextField
                      callback={(fieldContext) =>
                        handleFormValuesChange(
                          fieldContext,
                          'approvedBreakMinutes',
                        )
                      }
                      displayType="block"
                      fieldId="approvedBreakMinutes"
                      label="Break"
                      max="90"
                      min="0"
                      type="number"
                      width="100%"
                    />
                  </Stack>
                  <TimeNet
                    style={{
                      flex: '0 0 47%',
                    }}
                  >
                    Net Hours:&nbsp;
                    <span>
                      {getTotalLengthOfShiftTimesheet(approvedNetHours())}
                    </span>
                  </TimeNet>
                </Stack>
                <Stack vertical verticalGap={'11px'}>
                  <ProfileHeading>Tips</ProfileHeading>
                  <Stack css={{ position: 'relative' }}>
                    <AccountDollarIcon
                      style={{
                        position: 'absolute',
                        top: '36px',
                        left: '6px',
                        height: '14px',
                        width: '14px',
                        zIndex: 9,
                      }}
                    />
                    <TextField
                      callback={(fieldContext) =>
                        handleFormValuesChange(fieldContext, 'tipAmount')
                      }
                      displayType="block"
                      fieldId="tipAmount"
                      label="Tip Amount"
                      max="1000"
                      min="0"
                      step={tipsInCentsWeb ? '.01' : '1'}
                      style={{ paddingLeft: '30px' }}
                      type="number"
                      width="100%"
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Form>
            <MapAccordian />

            <Stack
              justify={'end'}
              style={{
                marginTop: '15px',
              }}
              vertical={phoneOnly}
              verticalGap={'12px'}
            >
              <PermissionComponent id="btn-save-timesheet">
                <Button
                  a11yLabel={`Save timesheet`}
                  appearance="outline"
                  form="timesheet-form"
                  id="btn-save-timesheet"
                  isLoading={
                    isSaveTimesheetLoading && !isApproveTimesheetLoading
                  }
                  label="Save"
                  style={phoneOnly ? { width: '100%' } : {}}
                  type="submit"
                  onClick={() => {
                    handleFormValuesChange({ value: true }, 'save');
                  }}
                />
              </PermissionComponent>

              <Stack css={!phoneOnly ? { width: 'auto' } : {}}>
                <PermissionComponent id="btn-reject-timesheet">
                  <Button
                    a11yLabel="Reject timesheet"
                    appearance="outline"
                    css={{ borderColor: '#DC1515', color: '#DC1515' }}
                    id="btn-reject-timesheet"
                    isLoading={isRejectTimesheetLoading}
                    label="Reject"
                    style={phoneOnly ? { width: '100%' } : {}}
                    type="button"
                    onClick={handleRejectTimesheet}
                  />
                </PermissionComponent>
                <PermissionComponent id="btn-approve-timesheet">
                  <Button
                    a11yLabel={`Approve timesheet`}
                    form="timesheet-form"
                    id="btn-approve-timesheet"
                    isLoading={isApproveTimesheetLoading}
                    label="Approve"
                    style={phoneOnly ? { width: '100%' } : {}}
                    type="submit"
                    onClick={() => {
                      handleFormValuesChange({ value: false }, 'save');
                    }}
                  />
                </PermissionComponent>
              </Stack>
            </Stack>
          </Stack>
        )}
        {variant === 'DETAIL' && (
          <>
            <MapAccordian />
            <Stack
              gap={'0'}
              style={{
                marginTop: '30px',
              }}
            >
              {timesheet?.status === TimesheetStatusEnum.APPROVED &&
                !currentAdminIsCustomerAdmin && (
                  <PermissionComponent id="btn-unapprove-timesheet">
                    <Button
                      a11yLabel="Unapprove timesheet"
                      appearance="secondary"
                      id="btn-unapprove-timesheet"
                      isLoading={isUnapproveTimesheetLoading}
                      label="Unapprove"
                      status="dark"
                      style={{ width: '100%' }}
                      onClick={() => handleUnapprove(timesheet.id)}
                    />
                  </PermissionComponent>
                )}
              {timesheet?.status === TimesheetStatusEnum.REJECTED &&
                !currentAdminIsCustomerAdmin && (
                  <PermissionComponent id="btn-unreject-timesheet">
                    <Button
                      a11yLabel="Unreject timesheet"
                      appearance="secondary"
                      id="btn-unreject-timesheet"
                      isLoading={isUnapproveTimesheetLoading}
                      label="Unreject"
                      status="danger"
                      style={{ width: '100%' }}
                      onClick={() => handleUnReject(timesheet.id)}
                    />
                  </PermissionComponent>
                )}
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default EditTimesheetModal;
