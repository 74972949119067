import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { ComponentProps, FC } from 'react';

import Card from '@/components/Card';
import IconicButton from '@/components/IconicButton';
import SingleColumnLayout from '@/components/SingleColumnLayout';
import { MIN_WORDS_TO_CAPITALIZE } from '@/constants/general';
import Overlay from '@/elements/Overlay';
import useClickout from '@/hooks/useClickout';
import useDisableBodyScroll from '@/hooks/useDisableBodyScroll';
import { capitalizeText } from '@/util/text';
import styled from '@/styles';
import { Accordion } from '@mui/material';

type Props = {
  disableClickout?: boolean;
  size?: ComponentProps<typeof SingleColumnLayout>['size'];
  title?: string;
  onRequestClose?: () => void;
  wrapperBackground?: boolean;
  modalPadding?: string;
  layoutCss?: Record<string, string | number>;
  modalHeader?: React.ReactNode;
  headerNobg?: boolean;
  headerSpacing?: boolean;
  displayFlex?: boolean;
  noHeader?: boolean;
};

export const HeaderDialog = styled('span', {
  padding: '10px 20px 15px 25px',
  background: 'linear-gradient(180deg, #EEFFEC 38.16%, #FFF 107.94%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TitleDialog = styled('span', {
  color: '#45A735',
  fontSize: '16px',
  fontWeight: '600',
});

export const StyledAccordion = styled(Accordion, {
  margin: '-5px 0 15px',
  '&.Mui-expanded': {
    margin: '0 !important',
  },
  '&:before': {
    display: 'none',
  },
  '.MuiAccordion-region .MuiAccordionDetails-root': {
    padding: '0',
  },
  'div[role="button"]': {
    padding: '0 !important',
  },
});

export const TimeIns = styled('span', {
  color: '#4367A6',
  fontSize: '12px',
  fontWeight: '600',
  margin: '16px 0 ',
});

export const TimeNet = styled('div', {
  color: '#4367A6',
  fontSize: '14px',
  fontWeight: '400',
  display: 'flex',
  width: '100%',
  span: {
    fontWeight: '700',
    marginLeft: '10px',
  },
});

const Modal: FC<Props> = ({
  children,
  disableClickout = false,
  size = 'md',
  title,
  onRequestClose,
  wrapperBackground = false,
  modalPadding = '72px 16px',
  layoutCss,
  modalHeader,
  headerNobg = false,
  noHeader = false,
  headerSpacing = false,
  displayFlex = false,
}) => {
  useDisableBodyScroll();

  const clickoutRef = useClickout<HTMLDivElement>(onRequestClose, {
    debugName: `Modal(${title})`,
    isActive: !disableClickout,
  });

  const titleWords = title?.split(' ');

  return (
    <Overlay
      aria-modal
      aria-label={title}
      data-testid="modal"
      role="dialog"
      onClick={() => {
        if (disableClickout === true && onRequestClose) {
          onRequestClose();
        }
      }}
    >
      <div
        style={
          displayFlex
            ? {
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }
            : { padding: modalPadding }
        }
      >
        <SingleColumnLayout css={layoutCss} size={size}>
          <Card
            ref={clickoutRef}
            css={{ boxShadow: '$2' }}
            changeStyle={{ borderRadius: '10px' }}
            headerNobg={headerNobg}
            headerSpacing={headerSpacing}
            id="modal"
            onClick={(e) => {
              if (disableClickout === true) {
                e.stopPropagation();
              }
            }}
          >
            {modalHeader}
            {!modalHeader && !noHeader && (
              <Card.Header
                titleColor="themeDefault"
                headerNobg={headerNobg}
                headerSpacing={headerSpacing}
                justify={!title ? 'end' : 'apart'}
                title={
                  titleWords && titleWords.length < MIN_WORDS_TO_CAPITALIZE
                    ? capitalizeText(title!)
                    : title
                }
                wrapperBackground={wrapperBackground}
              >
                {onRequestClose && (
                  <IconicButton
                    a11yLabel="Close Modal"
                    appearance="clear"
                    data-testid="close-modal-btn"
                    icon={faTimes}
                    id="close-modal-btn"
                    size="sm"
                    type="button"
                    onClick={onRequestClose}
                  />
                )}
              </Card.Header>
            )}

            {children}
          </Card>
        </SingleColumnLayout>
      </div>
    </Overlay>
  );
};

export default Modal;
